import React from "react";
import { Section } from "./Section";

export const History = () => {
  const styleContainer = {
    fontFamily: "bahnschrift",
    padding: "20px",
  };

  return (
    <div className="container" style={styleContainer}>
      <Section></Section>

      <div class="p-5 mb-4 bg-light rounded-3 row container-lg">
        <div class="container py-5 col">
          <h1 class="display-5 fw-bold">Nuestra historia</h1>
          <p class=" fs-4">
            {" "}
            Losper S.A. nace el 15 de Abril de 2001 como fusión de los más
            importantes distribuidores de YPF GAS en el Gran Mendoza. Nuestra
            misión siempre fue la misma, llevar un gas seguro, eficiente y de
            calidad a los hogares, empresas e industrias de nuestra provincia.
            Bajo el modelo de gestión y capacitación de YPF GAS, y con el
            compromiso de mejora continua, logramos la construcción del depósito
            de GLP más moderno de la región y la inversión de una flota de 20
            camiones de logística y distribución. Estos avances nos posicionan
            como líderes en el mercado y fortalecen nuestro compromiso con
            quienes confían en nosotros.
          </p>
          {/* <button class="btn btn-primary btn-lg" type="button">Example button</button> */}
        </div>
        <div className="col imgInfo">
          <img alt="imgHistoria" className="img-fluid" src="./img/2.jpg"></img>
        </div>
      </div>
      <div className="col imgInfoAlta">
        <img alt="imgHistoria" className="img-fluid" src="./img/2.jpg"></img>
      </div>
      <div class="row align-items-md-stretch mt-3">
        <div class="col-md-6">
          <div class="h-100 p-5 text-white bg-dark rounded-3">
            <h2>Visión</h2>
            <p>
              Ser una empresa orientada a la prestación de un servicio de
              excelencia, seguridad y calidad de producto.
            </p>
            {/* <button class="btn btn-outline-light" type="button">Example button</button> */}
          </div>
        </div>
        <div class="col-md-6">
          <div class="h-100 p-5 bg-light border rounded-3">
            <h2>Misión</h2>
            <p>
              Generar los procesos de gestión para lograr los Objetivos de la
              Visión.
            </p>
            {/* <button class="btn btn-outline-secondary" type="button">Example button</button> */}
          </div>
        </div>
        <div class="container py-5 col">
          <h3 class="display-6 fw-bold">Valores</h3>
          <ul class="  fs-3 list-group-numbered">
            <li class="list-group-item">Transparencia</li>
            <li class="list-group-item">Agilidad</li>
            <li class="list-group-item">Criterio</li>
            <li class="list-group-item">Sentido común</li>
            <li class="list-group-item">Homogeneidad de objetivos</li>
            <li class="list-group-item">Liderazgo interno </li>
          </ul>
          {/* <button class="btn btn-primary btn-lg" type="button">Example button</button> */}
        </div>
        <div class="container py-5 col">
          <h1 class="display-5 fw-bold">Póliticas de calidad</h1>
          <p class=" fs-4">
            {" "}
            La politica de calidad de LOSPER S.A. para la distribucion de Gas
            Licuado de Petroleo de YPF GAS es: CADA CLIENTE MERECE NUESTRO MEJOR
            ESFUERZO.
          </p>
          {/* <button class="btn btn-primary btn-lg" type="button">Example button</button> */}
        </div>
      </div>
    </div>
  );
};
