import React from "react";

export const FloatingBtn = () => {
  return (
    <div>
      <a href="https://walink.co/cc031c" className="wa-btn-float">
        <img alt="wa-icon" src="img/icons8-whatsapp-48.png"></img>
      </a>
    </div>
  );
};
